import 'assets/fonts/NotoSansKR/stylesheet.css';
import 'normalize.css';
import './libraries/react-select.css';

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    outline: none;
  }

  * {
    font-family: 'Noto Sans KR', sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  button {
    border: none;
    background: none;
  }

  h1 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    outline: none;
  }

  #root {
    height: 100vh;
    width: 100%;
    @media (max-width: 765px) {
      height: auto;
    }
  }

  div::-webkit-scrollbar, form::-webkit-scrollbar {
    width: 10px;
  }
  
  div::-webkit-scrollbar-track, form::-webkit-scrollbar-track {
    background: rgba(223, 223, 223, 0.3);
    border-left: 6px solid transparent;
    background-clip: padding-box;
  }
  
  div::-webkit-scrollbar-thumb, form::-webkit-scrollbar-thumb {
    background: #DFDFDF;
    border-left: 6px solid transparent;
    background-clip: padding-box;
  }
`;

export default GlobalStyles;
